import { createContext, useEffect, useState } from "react";
import axios from "../services/axios.service";

interface IUser {
    _id: string;
    username: string;
    isAdministrator: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export const AuthContext = createContext<IUser | any>({});
export const AuthProvider = ({ children }: any) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<IUser | any>(undefined);

    const populateUser = async ({ signal }: { signal: AbortSignal }) => {
        try {
            const accessToken = localStorage.getItem("accessToken");

            if (!accessToken) {
                return setIsLoading(false);
            }

            const {
                data: { success, data },
            } = await axios.get("auth/user", {
                signal,
            });

            if (success) {
                setUser(data);
                setIsAuthenticated(true);
                setIsLoading(false);
            }
        } catch (error: any) {
            if (error?.code !== "ERR_CANCELED") {
                setIsLoading(false);
            }
        }
    };

    const logout = async () => {
        setIsAuthenticated(false);

        localStorage.removeItem("accessToken");
        setUser(undefined);
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (localStorage.getItem("accessToken")) {
            populateUser({ signal });
        } else {
            setIsLoading(false);
        }

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                setIsAuthenticated,
                isLoading,
                user,
                setUser,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
