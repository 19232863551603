import { useEffect } from "react";

const Page404 = () => {
    useEffect(() => {
        window.location.replace(process.env.REACT_APP_LANDING_PAGE as string);
    }, []);

    return <></>;
};

export default Page404;
