import {
    AddRounded,
    ContentCopyRounded,
    ReplayRounded,
} from "@mui/icons-material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "../../services/axios.service";
import AuthRequired from "./AuthRequired";

const Dashboard = () => {
    const { user, logout } = useAuth();
    const [chips, setChips] = useState<any>(undefined);
    const [createdChip, setCreatedChip] = useState<any>(undefined);
    const [pageState, setPageState] = useState<any>({
        page: 0,
        pageSize: 5,
        isLoading: true,
        total: 0,
        sortModel: null,
    });

    const columns: GridColDef[] = [
        {
            field: "chipId",
            headerName: "Chip ID",
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: "linkedURL",
            headerName: "Linked URL",
            minWidth: 500,
            flex: 5,
            valueGetter(params) {
                return params.value || "Not Linked";
            },
            sortable: false,
        },
        {
            field: "visits",
            headerName: "Visits",
            type: "number",
            sortable: false,
            minWidth: 100,
            flex: 1,
        },
        {
            field: "clicks",
            headerName: "Clicks",
            type: "number",
            sortable: false,
            minWidth: 100,
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "Created At",
            sortable: true,
            minWidth: 200,
            flex: 1,
            valueGetter(params) {
                return new Date(params.value).toLocaleString("en-us");
            },
        },
    ];

    const getChips = async ({ signal }: { signal?: AbortSignal }) => {
        try {
            const {
                data: { success, data, total },
            } = await axios.get(
                `chips?page=${pageState.page}&perPage=${
                    pageState.pageSize
                }&sort=${
                    pageState.sortModel?.filter(
                        ({ field }: any) => field === "createdAt"
                    )[0].sort
                }`,
                { signal }
            );

            if (success) {
                setChips(data);
                setPageState((pageState: any) => ({
                    ...pageState,
                    isLoading: false,
                    total,
                }));
            }
        } catch (error: any) {
            if (error.code !== "ERR_CANCELED") {
                console.error(error);
            }
        }
    };

    const createChip = async () => {
        try {
            const {
                data: { success, data },
            } = await axios.post("chips");

            if (success) {
                setCreatedChip(data);
                getChips({});
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setPageState((pageState: any) => ({
            ...pageState,
            sortModel,
        }));
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        getChips({ signal });

        return () => {
            controller.abort();
        };
    }, [pageState.page, pageState.pageSize, pageState.sortModel]);

    return (
        <AuthRequired>
            <div className="w-full min-h-[100vh] bg-[#54B860] flex flex-col items-center justify-center gap-5">
                <div className="flex-shrink container w-full py-5 flex flex-col justify-center items-center">
                    <div>
                        <img
                            src="/assets/images/brand.png"
                            alt="Brand Logo"
                            className="w-80"
                        />
                    </div>
                </div>

                <div className="container">
                    <div className="w-full p-5 lg:p-10 bg-white rounded-xl shadow-xl">
                        <div className="flex flex-col lg:flex-row lg:items-center justify-between">
                            <h1 className="text-2xl lg:text-3xl">
                                Admin Dashboard
                            </h1>
                            <p className="text-xl lg:text-2xl">
                                Logged in as{" "}
                                <span className="font-medium">
                                    @{user?.username}
                                </span>
                                ,{" "}
                                <span
                                    onClick={() => {
                                        logout();
                                    }}
                                    className="text-[#54B860] underline cursor-pointer"
                                >
                                    Logout?
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container pb-10">
                    <div className="container w-full p-10 flex flex-col gap-5 bg-white rounded-xl shadow-xl">
                        <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-5">
                            <h2 className="text-2xl lg:text-3xl">
                                Chip Management・
                                <span className="font-medium">
                                    {pageState.total} chips
                                </span>
                            </h2>

                            <div className="w-full lg:w-auto flex items-center justify-between flex-wrap-reverse lg:flex-nowrap lg:justify-end gap-3">
                                {createdChip && (
                                    <div className="w-full lg:w-auto mx-auto lg:mx-0 flex items-center gap-2 rounded-xl outline outline-2 outline-[#54B860]">
                                        <p className="flex-grow truncate px-3 text-lg">
                                            {process.env.REACT_APP_URL}/c/
                                            {createdChip.chipId}
                                        </p>
                                        <div
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    `${process.env.REACT_APP_URL}/c/${createdChip.chipId}`
                                                );
                                            }}
                                            className="flex-shrink p-3 bg-[#54B860] rounded-r-lg cursor-pointer"
                                        >
                                            <ContentCopyRounded
                                                fontSize="medium"
                                                className="text-white"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="w-full lg:w-auto flex gap-5 mx-auto lg:mx-0">
                                    <button
                                        onClick={createChip}
                                        className="flex-grow w-full mx-auto lg:mx-0 p-3 lg:px-5 lg:py-3 text-xl flex items-center gap-3 text-white bg-[#54B860] rounded-xl"
                                    >
                                        <AddRounded fontSize="medium" />
                                        Create New
                                    </button>
                                    <button
                                        onClick={() => {
                                            getChips({});
                                        }}
                                    >
                                        <ReplayRounded fontSize="medium" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {chips !== undefined && (
                            <DataGrid
                                initialState={{
                                    sorting: {
                                        sortModel: [
                                            {
                                                field: "createdAt",
                                                sort: "desc",
                                            },
                                        ],
                                    },
                                }}
                                autoHeight
                                getRowId={(row) => row.chipId}
                                loading={pageState.isLoading}
                                rows={chips}
                                rowCount={pageState.total}
                                columns={columns}
                                paginationMode={"server"}
                                page={pageState.page}
                                pageSize={pageState.pageSize}
                                rowsPerPageOptions={[5, 10, 20]}
                                pagination
                                onPageChange={(newPage: number) => {
                                    setPageState((pageState: any) => ({
                                        ...pageState,
                                        isLoading: true,
                                        page: newPage,
                                    }));
                                }}
                                onPageSizeChange={(newPageSize: number) => {
                                    setPageState((pageState: any) => ({
                                        ...pageState,
                                        isLoading: true,
                                        pageSize: newPageSize,
                                    }));
                                }}
                                sortingMode={"server"}
                                onSortModelChange={handleSortModelChange}
                            />
                        )}
                    </div>
                </div>
            </div>
        </AuthRequired>
    );
};

export default Dashboard;
