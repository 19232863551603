import { FC } from "react";
import { useForm } from "react-hook-form";
import axios from "../services/axios.service";
import ChipLayout from "./ChipLayout";

interface LinkChipProps {
    chip: any;
    refresh: any;
    cancelEdit: () => void;
}
const LinkChip: FC<LinkChipProps> = ({ chip, refresh, cancelEdit }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm();

    const onSubmit = async (data: any) => {
        try {
            const { link } = data;

            const {
                data: { success },
            }: any = await axios.post(`chips/${chip.chipId}/link`, { link });

            if (success) {
                refresh();
            }
        } catch (error: any) {
            setError("link", {
                message: error.response.data.error.message,
            });
            console.error(error);
        }
    };

    return (
        <ChipLayout>
            <div className="flex-grow w-full h-full flex flex-col">
                {chip.linkedURL !== null && (
                    <div className="flex-shrink w-full h-40 flex items-center justify-end">
                        <p
                            onClick={() => {
                                cancelEdit();
                            }}
                            className="px-3 py-2 text-xl text-white rounded-xl cursor-pointer select-none"
                        >
                            Cancel
                        </p>
                    </div>
                )}

                <div className="my-auto flex-grow p-5 flex justify-center flex-col">
                    <div
                        className={`${
                            chip.linkedURL !== null && "-mt-40"
                        } p-5 flex justify-center flex-col gap-8 bg-white rounded-xl`}
                    >
                        <div className="flex flex-col gap-2">
                            <h1 className="text-3xl lg:text-4xl">
                                {chip.linkedURL !== null ? "Update" : "Link"} a
                                URL
                            </h1>
                            <p className="text-xl lg:text-2xl font-light">
                                Enter the link to a website below.
                            </p>
                        </div>

                        <div>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="flex"
                            >
                                <input
                                    type="text"
                                    defaultValue={chip?.linkedURL}
                                    placeholder="www.website.com"
                                    className={`w-full px-5 py-3 text-xl lg:text-2xl rounded-xl`}
                                    aria-invalid={
                                        errors.link ? "true" : "false"
                                    }
                                    {...register("link", {
                                        required: "Link is a required filed!",
                                    })}
                                />
                                <button
                                    type="submit"
                                    className="w-20 appearance-none text-xl lg:text-2xl text-center text-[#A781D3]"
                                >
                                    Save
                                </button>
                            </form>
                            {errors.link && (
                                <p
                                    className="text-xl text-red-400"
                                    role="alert"
                                >
                                    {errors.link.message?.toString()}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ChipLayout>
    );
};

export default LinkChip;
