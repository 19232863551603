import { PuffLoader } from "react-spinners";
import ChipLayout from "./ChipLayout";

const LoadingChip = () => {
    return (
        <ChipLayout>
            <div className="flex-grow flex flex-col items-center justify-center gap-5 relative">
                <PuffLoader color="white" size={250} />
                <h1 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[20ch] text-3xl font-bold text-white">
                    Loading...
                </h1>
            </div>
        </ChipLayout>
    );
};

export default LoadingChip;
