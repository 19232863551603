import axios, { AxiosError } from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { "X-Access-Token": localStorage.getItem("accessToken") },
});

instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            const {
                error: { message },
            }: any = error.response.data;

            console.log(`AuthError: ${message}`);
            localStorage.removeItem("accessToken");
        }
        throw error;
    }
);

export default instance;
