import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/admin/Dashboard";
import Login from "./components/admin/Login";
import Chip from "./components/Chip";
import Page404 from "./components/Page404";
import "./styles/globals.css";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                {window.location.host.split(".")[0] === "admin" && (
                    <>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/login" element={<Login />} />
                    </>
                )}

                <Route path="/c/:chipId" element={<Chip />} />

                <Route path="/admin" element={<Dashboard />} />
                <Route path="/admin/login" element={<Login />} />

                <Route path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
