import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { PuffLoader } from "react-spinners";
import useAuth from "../../hooks/useAuth";
import axios from "../../services/axios.service";

const Login = () => {
    const { isAuthenticated, setIsAuthenticated, isLoading, setUser } =
        useAuth();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm();

    const onSubmit = async ({ username, password }: any) => {
        try {
            const {
                data: { success, data },
            } = await axios.post("auth/login", { username, password });

            if (success) {
                localStorage.setItem("accessToken", data.accessToken);
                setUser(data.user);
                setIsAuthenticated(true);
            }
        } catch (error: any) {
            setError("password", {
                message: error.response.data.error.message,
            });
            console.error(error);
        }
    };

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            if (window.location.host.split(".")[0] === "admin") {
                window.location.replace(`http://${window.location.host}/`);
            } else {
                window.location.replace(`${process.env.REACT_APP_URL}/admin`);
            }
        }
    }, [isAuthenticated, isLoading]);

    if (isLoading) {
        return (
            <div className="w-full h-screen flex items-center justify-center">
                <PuffLoader color="white" size={150} />
            </div>
        );
    }

    return (
        <div className="w-full min-h-[100vh] bg-[#54B860] flex flex-col items-center justify-center">
            <div className="container w-full py-5 flex flex-col justify-center items-center">
                <img
                    src="/assets/images/brand.png"
                    alt="Brand Logo"
                    className="w-80"
                />
            </div>
            <div className="container py-10">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="mx-auto w-full lg:w-min p-10 flex flex-col gap-5 bg-white rounded-xl shadow-xl"
                >
                    <div>
                        <h1 className="text-2xl lg:text-3xl">
                            Admin Dashboard
                        </h1>
                    </div>

                    <div className="flex flex-col gap-2">
                        <label htmlFor="username" className="text-xl">
                            Username:
                        </label>
                        <input
                            id="username"
                            type="text"
                            autoComplete="off"
                            placeholder="@admin"
                            className={`w-full lg:w-80 px-5 py-3 bg-gray-100 rounded-xl ${
                                errors.username && "border-2 border-red-400"
                            }`}
                            aria-invalid={errors.username ? "true" : "false"}
                            {...register("username", {
                                required: "Username is a required field!",
                            })}
                        />
                        {errors.username && (
                            <span className="text-red-400" role="alert">
                                {errors.username?.message?.toString()}
                            </span>
                        )}
                    </div>

                    <div className="flex flex-col gap-2">
                        <label htmlFor="password" className="text-xl">
                            Password:
                        </label>
                        <input
                            id="password"
                            type="password"
                            autoComplete="off"
                            placeholder="superSecretPassword"
                            className={`w-full lg:w-80 px-5 py-3 bg-gray-100 rounded-xl ${
                                errors.username && "border-2 border-red-400"
                            }`}
                            aria-invalid={errors.password ? "true" : "false"}
                            {...register("password", {
                                required: "Password is a required field!",
                            })}
                        />
                        {errors.password && (
                            <span className="text-red-400" role="alert">
                                {errors.password?.message?.toString()}
                            </span>
                        )}
                    </div>

                    <button
                        type="submit"
                        className="w-full px-5 py-3 bg-[#54B860] text-xl text-white rounded-xl"
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
