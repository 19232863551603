import { useEffect, useState } from "react";
//@ts-ignore
import Marquee from "react-double-marquee";
import { useParams } from "react-router-dom";
import axios from "../services/axios.service";
import ChipLayout from "./ChipLayout";
import LinkChip from "./LinkChip";
import LoadingChip from "./LoadingChip";
import NotFoundChip from "./NotFoundChip";

const Chip = () => {
    const [chip, setChip] = useState<undefined | any | null>(undefined);
    const [isEditing, setIsEditing] = useState(false);

    const { chipId } = useParams();

    const fetchChip = async (signal: AbortSignal) => {
        try {
            const {
                data: { success, data },
            } = await axios.get(`chips/${chipId}`, { signal });

            if (success) {
                await axios.put(`chips/${chipId}/visit`, {
                    signal,
                });

                setChip(data);
                setIsEditing(!data.linkedURL);
            }
        } catch (error: any) {
            if (error.code !== "ERR_CANCELED") {
                setChip(null);
                console.error(error);
            }
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetchChip(signal);

        return () => {
            controller.abort();
        };
    }, [chipId]);

    if (chip === undefined) {
        return <LoadingChip />;
    }

    if (chip === null) {
        return <NotFoundChip />;
    }

    if (isEditing) {
        return (
            <LinkChip
                chip={chip}
                refresh={fetchChip}
                cancelEdit={() => {
                    setIsEditing(false);
                }}
            />
        );
    }

    const handleRedirect = async () => {
        await axios.put(`chips/${chip.chipId}/click`, {});

        window.location.href = chip.linkedURL;
    };

    return (
        <ChipLayout isBackgroundGreen={true} showHelp={true}>
            <div className="flex-grow w-full h-full flex flex-col">
                <div className="flex-shrink w-full h-40 flex items-center justify-end">
                    <p
                        onClick={() => {
                            setIsEditing(true);
                        }}
                        className="px-3 py-2 text-xl text-white rounded-xl cursor-pointer select-none"
                    >
                        Edit
                    </p>
                </div>

                <div className="w-full my-auto flex-grow md:p-5 flex justify-center flex-col gap-8 rounded-xl">
                    <div className="w-full flex flex-col">
                        <h1 className="max-w-[20ch] text-3xl lg:text-4xl font-bold text-white">
                            This chip is taking you to:
                        </h1>
                        <div className="w-full overflow-x-hidden">
                            <p className="whitespace-nowrap text-xl lg:text-2xl text-white font-medium">
                                <Marquee
                                    scrollWhen="overflow"
                                    delay={500}
                                    direction="left"
                                    children={chip.linkedURL.split("//")[1]}
                                />
                            </p>
                        </div>
                    </div>

                    <button
                        onClick={handleRedirect}
                        className="px-5 py-3 text-2xl lg:text-3xl font-bold bg-white text-[#54B860] rounded-xl"
                    >
                        Go to Website!
                    </button>
                </div>
            </div>
        </ChipLayout>
    );
};

export default Chip;
