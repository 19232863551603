import ChipLayout from "./ChipLayout";

const NotFoundChip = () => {
    return (
        <ChipLayout isBackgroundGreen={true} showHelp={true}>
            <div className="-mb-10 flex-grow flex flex-col items-center justify-center w-full md:p-5 gap-8 rounded-xl">
                <div className="w-full flex flex-col">
                    <h1 className="w-full max-w-[20ch] text-3xl lg:text-4xl font-bold text-white">
                        Chip not found!
                    </h1>
                    <p className="truncate max-w-[25ch] sm:max-w-[30ch] text-2xl text-white font-medium"></p>
                </div>
            </div>
        </ChipLayout>
    );
};

export default NotFoundChip;
