import { FC, ReactNode } from "react";

interface ChipLayoutProps {
    isBackgroundGreen?: boolean;
    showHelp?: boolean;
    children: ReactNode;
}
const ChipLayout: FC<ChipLayoutProps> = ({
    isBackgroundGreen = false,
    showHelp = false,
    children,
}) => {
    return (
        <div
            className={`w-full min-h-[100vh] flex flex-col items-center justify-center relative ${
                isBackgroundGreen ? "bg-[#54B860]" : "bg-[#A781D3]"
            }`}
        >
            <div className="flex-grow container w-full h-full sm:w-[500px] flex flex-col">
                {children}
            </div>
            <div className="flex-shrink container w-full py-5 flex flex-col justify-center gap-10 items-center">
                {showHelp && (
                    <a
                        href={process.env.REACT_APP_MORE_ABOUT_CHIP}
                        className="h-10 flex justify-center text-2xl lg:text-3xl font-medium text-[#297E33]"
                    >
                        What is chip?
                    </a>
                )}
                <div>
                    <p className="text-xl text-white">Brought to you by</p>
                    <a href={process.env.REACT_APP_LANDING_PAGE}>
                        <img
                            src="/assets/images/brand.png"
                            alt="Brand Logo"
                            className="w-80"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ChipLayout;
