import { FC, ReactNode, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import useAuth from "../../hooks/useAuth";

interface AuthRequiredProps {
    children: ReactNode;
}
const AuthRequired: FC<AuthRequiredProps> = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            if (window.location.host.split(".")[0] === "admin") {
                window.location.replace(`http://${window.location.host}/login`);
            } else {
                window.location.replace(
                    `${process.env.REACT_APP_URL}/admin/login`
                );
            }
        }
    }, [isAuthenticated, isLoading]);

    if (!isAuthenticated && !isLoading) {
        return (
            <div className="w-full h-screen flex items-center justify-center">
                <PuffLoader color="white" size={150} />
            </div>
        );
    }

    return <>{children}</>;
};

export default AuthRequired;
